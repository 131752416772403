@import "../mixins";

.vacancy {
  border-radius: 3px;
  overflow: hidden;

  & + & {
    margin-top: 10px;
  }

  &__top {
    background: #ffb941;
    padding: 20px 260px 20px 40px;
    position: relative;

    @include mq(959px) {
      padding: 25px 40px;
    }

    @include mq(719px) {
      padding: 20px 15px;
    }
  }

  &__title {
    font-size: 42px;
    font-weight: bold;
    line-height: 48px;

    @include mq(719px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__salary {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
  }

  &__link {
    position: absolute;
    right: 40px;
    top: calc(50% - 10px);

    @include mq(959px) {
      top: unset;
      bottom: 25px;
    }

    @include mq(719px) {
      position: static;
      margin-top: 8px;
    }
  }

  &__body {
    background: #F6F6F6;
    padding: 50px 40px;
    display: flex;

    @include mq(719px) {
      padding: 40px 25px 40px 15px;
      flex-direction: column;
    }
  }

  &-block {
    width: calc(50% - 20px);

    @include mq(719px) {
      width: 100%;
    }

    & + & {

      @include mq(720px, min) {
        margin-left: 40px;
      }

      @include mq(719px) {
        margin-top: 20px;
      }
    }

    &__title {
      font-size: 16px;
      font-weight: bold;
    }

    &__list {
      margin-top: 17px;

      li {
        position: relative;
        line-height: 24px;
        padding-left: 30px;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 8px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #FFB941;
        }
      }

      li + li {
        margin-top: .7rem;
      }
    }
  }

  &__orange-block {
    background: #FFB941;
    padding: 33px 30px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 3px;
    position: relative;

    @include mq(959px) {
      padding: 33px 0;

      &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        width: 100vw;
        height: 100%;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        background: #ffb941;
      }
    }
  }
}
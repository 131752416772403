@import "../mixins";

.eq {
  &__title {
    font-size: 42px;
    font-weight: bold;
    line-height: 48px;

    @include mq(959px) {
      font-size: 28px;
      line-height: 36px;
    }

    @include mq(719px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &-providers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-item {
      flex: 1;
      max-width: 50%;

      @include mq(719px) {
        width: 100%;
        flex: unset;
        max-width: unset;
      }

      & + & {
        margin-left: 100px;

        @include mq(1199px) {
          margin-left: 80px;
        }

        @include mq(719px) {
          margin: 30px 0 0;
        }
      }

      &__logo {
        height: 60px;

        @include mq(719px) {
          width: 215px;
          height: auto;

          img {
            width: 100%;
          }
        }
      }

      &__title {
        margin-top: 30px;
        font-size: 16px;
        font-weight: bold;
      }

      &__text {
        line-height: 24px;
        margin-top: 15px;
      }
    }
  }

  &-orange-block {
    background: #FFB941;
    padding: 50px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 3px;
    position: relative;
    transition: .3s;
    display: block;

    @include mq(719px) {
      padding: 30px 15px;
    }

    &:hover {
      background: $primaryHover;
    }

    &__title {
      font-size: 42px;
      font-weight: bold;
      line-height: 48px;

      @include mq(959px) {
        font-size: 28px;
        line-height: 36px;
      }

      @include mq(719px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__more {
      margin-top: 30px;
      display: flex;
      flex-direction: column;

      svg {
        margin-top: 10px;
      }
    }
  }
  &-bottom-hr {
    @include mq(720px, min) {

      display: block;
      margin-top: 40px;
      width: 100%;
      height: 0;
      border-bottom: 1px solid #CCC;
    }

    @include mq(719px) {
      display: none;
    }
  }

  &-detail-top {
    height: 360px;
    background: #414141;
    color: #FFF;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 50px calc(50% + 10px) 50px 90px;
    position: relative;
    margin-top: 29px;
    border-radius: 3px;

    @include mq(959px) {
      height: 300px;
      padding-left: 55px;
    }

    @include mq(719px) {
      height: auto;
      padding: 40px 15px 20px;
      width: calc(100% + 30px);
      margin-left: -15px;
      border-radius: 0;
    }

    &__img {
      position: absolute;
      right: 50px;
      top: 50%;
      transform: translateY(-50%);
      width: 50%;

      @include mq(959px) {
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      @include mq(719px) {
        transform: none;
        position: static;
        width: 100%;
        margin-top: 10px;
      }
    }

    &__title {
      font-size: 42px;
      line-height: 48px;
      font-weight: bold;
      margin-top: 10px;
      max-width: 300px;

      @include mq(959px) {
        font-size: 28px;
        line-height: 32px;
        max-width: 200px;
      }
    }
  }

  &-characteristics {
    &-item {
      display: flex;
      font-size: 16px;
      line-height: 22px;
      justify-content: space-between;

      @include mq(719px) {
        font-size: 12px;
        line-height: 18px;
      }

      & + & {
        margin-top: 10px;
      }

      &__label {
        width: calc(66% - 5px);
        overflow: hidden;
        padding: 13px 20px;
        background: #F4F4F4;
        border-radius: 3px;

        @include mq(719px) {
          display: flex;
          align-items: center;
          padding: 5px 10px;
          word-break: break-word;
        }
      }

      &__value {
        width: calc(33% - 5px);
        margin-left: 10px;
        padding: 13px 20px;
        background: #F4F4F4;
        border-radius: 3px;

        @include mq(719px) {
          display: flex;
          align-items: center;
          word-break: break-word;
          padding: 5px 10px;
        }
      }
    }
  }

  &-manufacturer {
    display: flex;
    position: relative;
    overflow: hidden;

    &:after, &:before {
      content: "";
      display: block;
      position: absolute;
      width: 60px;
      height: 200px;
      background: #E31E24;
      transform: rotate(20deg);
      top: -20px;

      @include mq(719px) {
        display: none;
      }
    }

    &:after {
      left: 46%;

      @include mq(959px) {
        left: 45%;
      }
    }

    &:before {
      left: 38%;

      @include mq(959px) {
        left: 34%;
      }
    }

    &__site {
      width: 50%;
      background: #FFB941;
      height: 120px;
      align-items: center;
      padding: 30px 40px;
      line-height: 30px;
      font-weight: bold;

      span {
        text-decoration: underline;
      }

      @include mq(719px) {
        width: 100%;
      }
    }

    &__logo {
      width: 50%;
      background: #DBDBDB;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mq(959px) {
        padding: 20px 50px;

        img {
          max-width: 100%;
        }
      }

      @include mq(719px) {
        display: none;
      }
    }
  }

  &-certificate-item {
    transition: .3s;

    &:hover {
      filter: grayscale(1);
    }
  }
}
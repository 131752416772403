@import "./colors";
@import "./mixins";

.btn {
  outline: none;
  display: inline-block;
  border-radius: 3px;
  padding: 10px 20px;
  cursor: pointer;
  transition:.3s;
  text-align: center;
  font-size: 16px;

  &--primary {
    color: #FFF;
    background: $primary;

    &:hover {
      background: $primaryHover;
    }
  }

  &--secondary {
    color: #FFF;
    border: 1px solid #828282;

    &:hover {
      background: #7D7D7D;
    }
  }
}

.select2-container--open {
  z-index: 12;
}

.select2-search--hide {
  display: none;
}

.select2-hidden-accessible {
  display: none;
}

.select2-results__option[aria-selected=true] {
  display: none;
}

.select2-dropdown--below {
  display: block;
}

.select2-results {
  background: #343434;
  color: #FFF;
  border: 1px solid #888888;
  border-radius: 0 0 3px 3px;
  font-size: 16px;
  padding: 0 15px 14px;
  display: block;
  border-top: none;
  transform: translateY(-10px);
}

.select2-results__option + .select2-results__option {
  margin-top: 5px;
}

.select2-results__option {
  transition: .3s;
  color: #FFF;
  cursor: pointer;

  &:hover {
    color: #E68A00;
  }
}

.select2-selection__arrow {
  display: block;
  position: absolute;
  height: 7px;
  width: 15px;
  top: calc(50% - 4px);
  right: 18px;
  background: url(../images/select-arrow.png);
  background-size: contain;
  transition: .3s;
}

.select2-container--open .select2-selection__arrow {
  transform: rotateX(180deg);
}

.popup {
  position: fixed;
  z-index: 12;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: none;
  justify-content: center;
  align-items: baseline;
  padding: 80px 15px 15px;

  @include mq(719px) {
    padding: 0;
  }

  &--calc {
    @include mq(1200px, min) {
      display: none !important;
    }

    @include mq(767px) {
      padding-top: 60px;
    }
  }
  
  &__overlay {
    background: rgba(#dedede, .98);
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &-body {
    position: relative;

    @include mq(959px) {
      width: calc(100% - 70px);
    }

    @include mq(719px) {
      width: 100%;
    }
  }

  &__close {
    position: absolute;
    cursor: pointer;

    rect {
      transition: .3s;
    }

    &:hover rect {
      fill: #C80000;
    }

    &--calc {
      top: -50px;
      right: 0;
    }

    &--order-form {
      right: 7px;
      top: 7px;
    }
  }
}

.custom-checkbox {
  position: relative;
  background: #666666;
  border-radius: 3px;
  width: 19px;
  height: 19px;
  z-index: 1;
  border: 1px solid #666666;
  transition: .3s;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1;

    + svg {
      opacity: 0;
    }

    &:checked + svg {
      opacity: 1;
    }
  }

  svg {
    position: absolute;
    z-index: -1;
    left: 2px;
    top: 0;
    transition: .3s;

    path {
      transition: .3s;
    }
  }



  &:hover {
    background: #ffffff;

    input + svg {
      path {
        stroke: #666666;
      }
    }
  }
}

.custom-file-input {
  position: relative;
  display: inline-block;

  input[type="file"] {
    font-size: 0;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    left: 0;
    top: 0;
    opacity: 0;

    + span {
      background: #7d7d7d;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 19px 40px;
      width: 100%;
      transition: .3s;

      svg {
        margin-left: 10px;
      }
    }

    &:hover + span {
      background: #444444;
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  margin-top: 22px;

  li {
    @include mq(959px) {
      padding-left: 20px;
      position: relative;

      &:not(:first-child) {
        display: none;
      }

      &:before {
        content: url('../images/breadcrumbs-arrow-fff.png');
        display: block;
        position: absolute;
        left: 0;
        top: 2px;
        filter: brightness(.3);
      }
    }

    a {
      display: block;
      margin-right: 17px;
      position: relative;
      transition: .3s;

      &:hover {
        color: $primaryHover;
      }

      @include mq(960px, min) {
        &:after {
          content: url('../images/breadcrumbs-arrow.png');
          display: block;
          position: absolute;
          right: -17px;
          top: 2px;
        }
      }

    }

    &:last-child {
      color: #999999;
    }
  }

  li + li {
    margin-left: 12px;
  }
}

.contacts-map {
  height: 416px;
  position: relative;
  z-index: 11;
  background: #FFF;

  @include mq(719px) {
    height: 100vw;
    max-height: 415px;
  }
}

[class*="ymaps-2-1"][class*="-ground-pane"] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  /* Firefox 3.5+ */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
  filter: grayscale(100%);
}

[class*="ymaps-2"][class*="-ground-pane"] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  /* Firefox 3.5+ */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}

.underline-link:not(:hover) {
  text-decoration: underline;
}

.page-title {
  font-size: 42px;
  line-height: 57px;
  margin-top: 40px;

  @include mq(959px) {
    font-size: 28px;
    line-height: 33px;
    margin-top: 30px;
  }

  @include mq(719px) {
    font-size: 24px;
    line-height: 28px;
  }
}

.button-checkbox {
  position: relative;
  display: inline-block;
  margin: 5px;

  [type=checkbox] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  &__btn {
    color: #999999;
    background: #f6f6f6;
    min-width: 170px;

    @include mq(719px) {
      font-size: 12px;
      min-width: unset;
    }
  }

  &:hover {
    .button-checkbox__btn {
      color: #E68A00;
    }
  }

  [type=checkbox]:checked + .button-checkbox__btn {
    background: #ffb941;
    color: #333;
  }

  [type=checkbox]:checked:hover + .button-checkbox__btn {
    background: #E68A00;
  }
}
@import "../mixins";

.inner-top-block {
  position: relative;
  padding: 27px 0 80px;
  color: #fff;

  @include mq(959px) {
    padding-bottom: 70px;
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__breadcrumbs {
    margin-top: 0;

    li:before {
      filter: none !important;
    }
  }

  &__title {
    font-size: 100px;
    line-height: 136px;
    margin-top: 20px;

    @include mq(959px) {
      font-size: 70px;
      line-height: 96px;
      margin-top: 35px;
    }

    @include mq(719px) {
      font-size: 24px;
      line-height: 33px;
    }
  }
}
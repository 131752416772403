@import "../colors";
@import "../mixins";

.order-form {
  padding: 50px 90px;
  width: 800px;
  max-width: 100%;
  background: #f6f6f6;
  border-radius: 5px;
  max-height: 80vh;
  overflow: auto;

  @include mq(959px) {
    padding: 50px 45px;
    width: 100%;
  }

  @include mq(719px) {
    padding: 50px 15px;
    max-height: 100vh;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    color: #222;
  }

  &__subtitle {
    color: #666666;
    margin-top: 5px;
    font-size: 12px;
  }

  &__inputs {
    margin: 25px -10px -13px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__input-parent {
    background: #F4F4F4;
    z-index: 1;
    transition: .3s;
    position: relative;
    border: 1px solid #C8C8C8;
    border-radius: 5px;
    height: 66px;
    margin: 13px 10px;
    width: 100%;

    &--half-width {
      width: calc(50% - 20px);

      @include mq(719px) {
        width: 100%;
      }
    }

    label {
      position: absolute;
      left: 20px;
      top: 22px;
      font-size: 16px;
      color: #666;
      transition: .1s;
      z-index: -1;

      @include mq(719px) {
        font-size: 14px;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 6px;
        height: 9px;
        border: 2px solid #53BF31;
        border-radius: 0 0 3px 0;
        border-style: none solid solid none;
        transform: rotate(45deg);
        top: 3px;
        right: -15px;
        transition: .3s;
        opacity: 0;
      }
    }

    &.got-value {
      background: #FFF;

      label {
        font-size: 12px;
        top: 8px;
        z-index: -1;

        @include mq(719px) {
          top: 7px;
        }
      }
    }

    &.required {
      &:not(.correct):not(.chosen):before {
        content: "*";
        display: block;
        position: absolute;
        right: 8px;
        top: 8px;
        opacity: .6;
        color: #222;
      }
    }



    &.correct, &.chosen {
      label:after {
        opacity: 1;
      }
    }

    &.required.error {
      background: #FFDCDC;
    }
  }

  &__phone-input {
    display: flex;
    width: 50%;

    @include mq(719px) {
      width: 100%;
    }

    .required {
      width: 200px;

      @include mq(959px) {
        width: 180px;
      }

      + div {
        flex: 1
      }
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    padding: 30px 20px 15px;
    font-size: 16px;

    &:focus + label {
      font-size: 12px;
      top: 8px;
      z-index: -1;
    }
  }

  &-nav {
    display: flex;
    margin-top: 55px;
    justify-content: space-between;

    @include mq(719px) {
      flex-direction: column;
      margin-top: 25px;
    }

    &__right {
      width: calc(50% - 10px);
      text-align: center;

      @include mq(719px) {
        width: 100%;
      }
    }
  }

  &__agreement {
    display: flex;
    align-items: center;
    height: 60px;

    label {
      color: #666666;
      margin-left: 17px;
      font-size: 12px;
    }
  }

  &__btn {
    width: 100%;
    height: 60px;
    line-height: 40px;
    font-size: 16px;
  }

  &__required-caption {
    font-size: 12px;
    color: #666666;
    margin-top: 18px;
  }

  &__error {
    margin-top: 20px;
    text-align: center;
    display: none;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    border-top: 1px solid #C8C8C8;

    @include mq(719px) {
      text-align: left;
      padding-top: 15px;
    }

    span {
      color: #970000;
      margin-left: 20px;
      font-size: 16px;
    }
  }

  &-step-2-inputs {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    @include mq(719px) {
      flex-wrap: wrap;
    }

    .order-form__input-parent {
      margin: 0;
    }

    .order-form__input-parent + .order-form__input-parent {
      margin-top: 26px;
    }

    &__left, &__right {
      width: calc(50% - 10px);

      @include mq(719px) {
        width: 100%;
      }
    }

    &__right {
      @include mq(719px) {
        margin-top: 25px;
      }
    }

    .order-form__input-parent--textarea {
      height: 100%;
    }
  }

  &__button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 55px;

    @include mq(719px) {
      flex-wrap: wrap;
      margin-top: 25px;
    }

    .custom-file-input {
      width: calc(50% - 10px);

      @include mq(719px) {
        width: 100%;
        height: 60px;
      }
    }

    .btn {
      width: calc(50% - 10px);

      @include mq(719px) {
        width: 100%;
        height: 60px;
        margin-top: 25px;
      }
    }
  }

  &-documents {
    margin-top: 55px;
    padding-top: 25px;
    border-top: 1px solid #C8C8C8;

    &__title {
      font-size: 16px;
      color: #606060;
    }

    &__row {
      margin: 10px -10px -10px;
      display: flex;
      flex-wrap: wrap;
    }

    &-item {
      position: relative;
      font-size: 12px;
      color: #666666;
      padding-right: 20px;
      margin: 10px;

      &:before {
        content: url('../images/doc.png');
        display: inline-block;
        margin-right: 3px;
      }

      svg {
        cursor: pointer;
        position: absolute;
        top: 2px;
        right: 0;

        line {
          transition: .3s;
        }
      }

      &:hover {
        line {
          stroke: #C80000;
        }
      }
    }
  }

  &-success {
    &__title {
      font-size: 16px;
      font-weight: bold;
      color: #222222;
    }

    &__subtitle {
      color: #666666;
      font-size: 12px;
    }

    &__img {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px 10px 35px;
    }

    &__promise {
      font-size: 16px;
      text-align: center;
      color: #333333;
    }

    &__btn {
      margin-top: 40px;
      display: flex;
      justify-content: center;

      .btn {
        width: 300px;
        max-width: 100%;
      }
    }
  }
}
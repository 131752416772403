@import "../mixins";

.reviews {
  &__text-block {
    max-width: 740px;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
  }

  &-item {
    margin: 5px;
    width: calc(25% - 10px);
    transition: .3s;
    padding: 20px;
    min-height: 440px;
    border-radius: 3px;

    @include mq(959px) {
      width: calc(33.3% - 10px);
      background: #f4f4f4;
    }

    @include mq(719px) {
      width: 100%;
    }

    &:hover {
      background: #f4f4f4;
    }

    &__img {
      height: 308px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__title {
      margin-top: 20px;
    }
  }
}
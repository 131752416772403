.front-banner {
  padding: 40px 50px;
  position: relative;
  z-index: 1;
  background: #FFB941;
  transition: .3s;
  display: block;
  overflow: hidden;

  @include mq(719px) {
    padding: 30px 15px;
  }

  &__img {
    position: absolute;
    bottom: 0;
    right: 50px;
    width: 250px;
    height: 217px;
    z-index: -1;
    transition: .3s;

    @include mq(1449px) {
      width: 200px;
      height: 174px;
    }

    @include mq(959px) {
      width: 140px;
      height: 121px;
    }

    @include mq(719px) {
      width: 120px;
      height: 104px;
      right: 10px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    font-size: 42px;
    line-height: 48px;
    font-weight: bold;
    color: #000;

    @include mq(1449px) {
      font-size: 32px;
      line-height: 38px;
    }

    @include mq(959px) {
      font-size: 28px;
      line-height: 36px;
    }

    @include mq(719px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__more {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    font-size: 16px;
    color: #000;

    svg {
      margin-top: 15px;
    }
  }



  &:hover {
    background: #ef9b1d;

    .front-banner__img {
      opacity: .5;
      transform: translateY(10%);
    }
  }
}
@import "../mixins";

.legal {
  &__block {
    padding: 40px 0;
    line-height: 24px;
    max-width: 620px;

    @include mq(959px) {
      padding: 25px 0;
    }

    & + & {
      border-top: 1px solid #CCCCCC;
    }
  }

  b {
    font-size: 16px;
  }
}